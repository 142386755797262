.text {
  font-weight: var(--bold);
  padding: 4px;
  text-align: center;
}

.warning {
  background: #1a1a1a;
  color: white;
  text-transform: uppercase;
}
