@import "mixins";

.page {
  overflow: hidden;
  // padding here to position progress bar

  @include desktop {
    padding: 32px var(--main-padding-horizontal);
  }

  @include mobile {
    padding: 28px 0;
  }
}

.grid {
  display: grid;

  @include desktop {
    gap: 18px;
  }

  @include mobile {
    gap: 16px;
  }
}

.sub {
  padding: unset;
  margin-top: 20px;
}

.header {
  @include flex(flex-start);

  @include desktop {
    --font-size-page-title: 24px;
  }

  @include mobile {
    --font-size-page-title: 24px;
    padding: 0 var(--main-padding-horizontal);
  }
}

.title {
  font-size: var(--font-size-page-title);
  color: var(--y-text);
  margin-right: 5px;
  font-weight: 200;

  &:hover,
  &.active {
    font-weight: 500;
  }
}

.titleSelected {
  font-size: var(--font-size-page-title);
  color: var(--y-text);
  margin-right: 5px;
  font-weight: 500;
}

.sub {
  .header {
    @include mobile {
      display: grid;
      gap: 8px;
    }
  }

  .title {
    font-size: 24px;
  }
}

@include desktop {
  .small {
    gap: 36px;

    .header {
      border-bottom: 1px solid var(--card-border);
      padding-bottom: 18px;
    }

    .main {
      margin: 0 auto;
      width: var(--max-width-small);
      max-width: 100%;
    }
  }
}

.main {
  overflow: hidden;
}
