.total {
  font-size: 28px;
  font-weight: var(--bold);
}

.number {
  font-size: 20px;
  font-weight: var(--normal);
}

.small {
  color: var(--text-muted);
  font-size: var(--font-size-small);
}
