@import "mixins";

.modes {
  text-align: right;
}

.header {
  @include flex(space-between);
  font-size: var(--font-size-small);
  font-weight: var(--bold);
}
